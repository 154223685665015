// 病害分布图
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'ZLMC',
                label: '资料名称',
                minWidth: 105,
                showoverflowtooltip: true
            }, {
                prop: 'SYT',
                label: '示意图',
                showImg: true,
            }, {
                prop: 'LJ',
                label: '图纸',
                showFileBtn: true,
            }],
            // 操作列
            columnsBtn: {
                buttonData: []
            },
        },
    },
};
export default selfData;